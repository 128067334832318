<template>

    <div class="wrapper">
        <div class="animated fadeIn">
            <!-- 제품 정보 --------------------------------------------------------------------------------------------->
            <BCard>
                <BCard header-tag="header" footer-tag="footer" class="mb-1" >

                    <div slot="header">
                        <BIconBagPlusFill/> <strong> 제품정보 </strong>
                        <div class="card-header-actions">
                            <small class="text-muted">제품 리스트</small>
                        </div>
                    </div>

                    <BRow class="mb-2">
                        <BCol sm="3">
                            <BInputGroup>
                                <BButton size="sm" variant="warning" @click="insertProductInfo">
                                    <BIconPlusCircleFill/> 제품 추가
                                </BButton>
                            </BInputGroup>
                        </BCol>
                        <BCol sm="4">
                            <BInputGroup >
                                <template v-slot:prepend>
                                    <BFormSelect size="sm"
                                                 v-model="searchField"
                                                 :options="searchOpts"/>
                                </template>
                                <BFormInput size="sm"
                                            v-model="searchWord"
                                            type="text"
                                            @keyup.enter="getProductInfoList"/>
                            </BInputGroup>
                        </BCol>
                        <BCol>
                            <BButtonGroup>
                                <BButton size="sm" variant="primary" @click="getProductInfoList"><BIconArrowRepeat/> Refresh</BButton>
                                <BButton size="sm" variant="info" class="ml-1" @click="exportExcel"><BIconFileSpreadsheetFill/> Excel Download</BButton>
                            </BButtonGroup>
                        </BCol>
                    </BRow>

                    <BRow>
                        <BCol>
                            <vue-excel-editor
                                    v-model="rows"
                                    ref="productInfoGrid"
                                    width="100%"
                                    class="mb-1"
                                    :page="numOfRecord"
                                    @update="updateProductInfo"
                                    @select="selectProductInfo"
                                    :readonly-style="{backgroundColor:'#FEF'}"
                                    :localized-label="$localizedLabel"
                                    filter-row>
                                <vue-excel-column field="prd_seq" label="번호" type="number" width="60px" key-field readonly/>
                                <vue-excel-column field="prd_code" label="제품코드" width="100px"/>
                                <vue-excel-column field="prd_name" label="제품명" width="100px"/>
                                <vue-excel-column field="disp_name" label="표시명" width="200px"/>
                                <vue-excel-column field="warranty_mon" label="WT(월)" width="100px"/>
                                <vue-excel-column field="unit_price" label="가격" type="number" width="100px"/>
                                <vue-excel-column field="use_yn" label="사용" type="checkYN" width="60px"/>
                                <vue-excel-column field="pub_dt" label="게시일" type="date" width="100px"/>
                                <vue-excel-column field="end_dt" label="종료일" type="date" width="100px"/>
<!--                                <vue-excel-column field="file_nm" label="이미지" :link="showImage" width="150px"/>-->
<!--                                <vue-excel-column field="desc_url" label="URL" :link="gotoUrl" width="150px"/>-->
                                <vue-excel-column field="description" label="설명" width="300px"/>
                                <vue-excel-column field="upd_dt" label="수정일" type="date" width="90px" readonly/>
                                <vue-excel-column field="reg_dt" label="등록일" type="date" width="90px" readonly/>
                                <vue-excel-column field="upd_id" label="수정자" width="80px" readonly/>
                                <vue-excel-column field="reg_id" label="등록자" width="80px" readonly/>

                                <vue-excel-column field="discount_yn" label="할인여부" type="checkYN" width="80px"/>
                                <vue-excel-column field="discount_end_dt" label="할인종료일자" type="date" width="160px"/>
                                <vue-excel-column field="discount_price" label="할인금액" type="number" width="100px"/>
                                <vue-excel-column field="sale_file_nm" label="판매이미지" :link="showImage" width="150px"/>

                                <vue-excel-column field="promotion_yn" label="홍보여부" type="checkYN" width="80px"/>
                                <vue-excel-column field="sale_yn" label="판매여부" type="checkYN" width="80px"/>
                                <vue-excel-column field="sort" label="노출순서" type="number" width="80px"/>

                                <vue-excel-column field="upgrade_yn" label="업그레이드" type="checkYN" width="80px"/>
                                <vue-excel-column field="upg_bef_prd_code" label="업그레이드 제품코드" width="140px"/>
                            </vue-excel-editor>
                        </BCol>
                    </BRow>

                    <!-- Site/Theater Table -->
                    <BRow>
                        <BCol>
                            <BButtonGroup>
                                <BButton variant="danger" @click="deleteProductInfo">
                                    <BIconTrashFill/> 제품정보 삭제
                                </BButton>
                            </BButtonGroup>
                        </BCol>

                        <BCol>
                            <BProgress height="2rem" :value="progCount" :max="totalCount" variant="warning" show-value/>
                        </BCol>
                    </BRow>


                </BCard>

                <BCard v-if="selectedRow!=null">
                    <div slot="header">
                        <BRow>
                            <BCol sm="6">
                                <BButton variant="warning" size="sm"><b>{{selectedRow.prd_name}}</b> <BBadge variant="primary"> {{selectedRow.prd_code}}</BBadge></BButton>
                                <BButton variant="dark" size="sm" class="ml-1"> {{selectedRow.disp_name}}</BButton>
                            </BCol>
                            <BCol>
                                <BFormFile size="sm" accept="image/*" v-model="imgFile" @change="uploadImage"/>
                            </BCol>
                            <BCol>
                                <BButton size="sm" variant="warning" @click="uploadImageData" v-show="imgFile!=null">선택파일 업로드</BButton>
                            </BCol>
                        </BRow>
                    </div>
                    <BRow>
                        <BCol>
                            <img v-show="previewImage" :src="previewImage" width="100%" />
                        </BCol>
                    </BRow>
                    <BRow>
                        <BCol v-if="imgSrc">
                            <BImg  :src="imgSrc" fluid/>
                        </BCol>
                    </BRow>
                </BCard>


            </BCard>

        </div>
    </div>
</template>

<script>
    //-------------------------------------------------------------------------------------------------
    import {
        alertModal,
        apiCall,
        cloneVar,
        confirmModal,
        checkBisNo,
        alertConfirm,
        alertSync,
        alertWarn,
        alertSuccess,
        apiMultiPart
    } from '../../../common/utils';
    import qs from 'querystring';
    import moment from "moment";
    //----------------------------------------------------------------------------------------------------
    const _prdInfo = {
        prd_code     : null,
        prd_name     : null,
        disp_name    : null,
        warranty_mon : null,
        unit_price   : null,
        description  : null,
        use_yn       : null,
    };
    export default {
        name: 'ProductInfo',
        components: {},
        data () {
            return {
                searchOpts: [
                    {value:'prd_name', text:'제품명'},
                    {value:'prd_code', text:'제품코드'},
                ],
                rows: [],
                /////////////////////////////////////////////////////
                searchWord : null,
                searchField: 'prd_key',
                compActionMode: 'POST',
                comp: null,
                compItems : [],
                numOfRecord: 20,
                productKeyFileName: `product-key-${moment().format('YYYYMMDD')}.xls`,
                progCount: 0,
                totalCount: 0,

                previewImage: null,
                imgFile: null,
                selectedRow: null,
                imgSrc: null
            }

        },
        async created(){
            try{
                await this.getProductInfoList();
            }catch(err){

            }
        },
        computed: {

        },
        mounted() {
            console.log("mounted---------------------ProductInfo");
            // below is not work!
        },

        methods: {
            gotoUrl(url){
                window.open(url, "_blank");
            },
            async showImage(imgFile){
                window.open( `${$baseURL}/images/${imgFile}`, "_blank");
            },
            async getProductInfoList(){
                let r = null, param = {};
                let qry = '';
                if( this.searchWord !== null){
                    param[this.searchField] = this.searchWord;
                    qry = qs.stringify( param );
                }
                try{
                    r = await apiCall('get', `/admin/api/product/info?${qry}`);
                    this.rows = r.result;
                    // console.log("this.rows ==========> ", this.rows);
                }catch(err){
                    console.log( err );
                }

            },
            uploadImage(e){
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                    // console.log(this.previewImage);
                };
            },
            async uploadImageData(){
                let formData = new FormData();
                // for( let file of this.imgFile ){
                // }
                formData.append("images", this.imgFile);

                try{
                    const r = await apiMultiPart(`/admin/api/product/image-upload/${this.selectedRow.prd_seq}`, formData);
                    console.log("apiMultiPart ----------------> result---------------");
                    console.log( r );

                    if( r.code===200){
                        await alertSuccess( this.$bvModal, "이미지가 저장 되었습니다.", "파일 업로드");
                    }else{
                        await alertWarn( this.$bvModal, `Message: ${r.message}`, "파일 업로드 실패");
                    }
                }catch(err){
                    console.log(err);
                }finally{
                    this.isItemLoading = false;
                }
            },
            exportExcel(){
                const format = 'xlsx';
                const exportSelectedOnly = true;
                const filename = 'product-info-'+moment().format('YYYY-MM-DD');
                this.$refs.productInfoGrid.exportTable(format, exportSelectedOnly, filename)
            },

            async insertProductInfo(){

                let r = null;
                const yymm =  moment().format("YYMM");

                try{
                    r = await apiCall('POST', `/admin/api/product/info`, {prefix: yymm} );
                    if( r.result && r.result.prd_code ){
                        await this.getProductInfoList();
                    }
                }catch(err){
                    console.log( err );

                }

            },

            async selectProductInfo(idx, evt){
                this.imgSrc = null;
                if(!evt) {
                    this.selectedRow = null;
                    return;
                }
                try{
                    const arr = this.$refs['productInfoGrid'].getSelectedRecords();
                    if(!arr.length) return;
                    console.log( "selectProductInfo ----------> ", arr );
                    this.selectedRow = arr[0];
                    if(this.selectedRow && this.selectedRow.file_nm) {
                        // this.previewImage = $baseURL+"/" + this.selectedRow.file_nm;
                        this.imgSrc = $baseURL + "/images/" + this.selectedRow.file_nm;
                    }else{
                        this.imgSrc = null;
                    }

                    // this.dispKey = arr[0].disp_key;
                    // await this.getProductTokenList( arr[0] );
                }catch(err){
                    console.log(err);
                }
            },

            async updateProductInfo(rec){
                let r = null, msg = null, notice = null;

                try{

                    let rc = rec[0];
                    if(!rc.keys[0]){
                        console.log( "nokey" );
                        return;
                    }
                    const tIndex = this.$refs['productInfoGrid'].rowIndex[rc.$id];
                    console.log( "updateProductInfo--------- rec[0]|rc --->",rc );
                    console.log( "updateProductInfo--------- rec[0].$id--->",rc.$id );
                    console.log( "updateProductInfo--------- rec[0].$id--->",this.$refs['orderGrid']);
                    console.log( "updateProductInfo--------- rowIndex--->",tIndex );
                    let row = this.$refs['productInfoGrid'].table[tIndex];

                    let param = {};
                    const prdSeq = rec[0].keys[0];
                    const fieldName = rec[0].name;
                    const newVal = rec[0].newVal;

                    param['prd_seq'] = prdSeq;
                    param[fieldName] = newVal;

                    r = await apiCall("PUT", `/admin/api/product/info`, param);
                    if( !r.result || !r.result.affectedRows ){
                        await alertWarn( this.$bvModal, `Message: ${r.message}`, "[에러] 업데이트 실패");
                        this.$refs.productInfoGrid.undoTransaction();
                    }

                }catch(err){
                    console.log( err );
                }
            },

            async deleteProductInfo(recs){
                try{
                    console.log( recs );
                    let params = this.$refs['productInfoGrid'].getSelectedRecords();
                    this.progCount = 0;
                    this.totalCount = params.length;
                    console.log( "deleteProductInfo ----> ", params );
                    console.log( "deleteProductInfo length ----> ", params.length );
                    if(!params.length) return;

                    const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없습니다. 진행 하시겠습니까?`;
                    if( !(await alertConfirm(this.$bvModal, confirmMsg, '제품정보 삭제')) ){
                        return;
                    }

                    let failCnt = 0;
                    for( let info of params){
                        console.log( "deleteProductInfo for --------->", info );
                        const tIndex = this.$refs['productInfoGrid'].rowIndex[info.$id];
                        const r = await apiCall('POST', `/admin/api/product/delete-product`, info );
                        console.log( "result --------------> ", r );
                        if( r.result && r.result.affectedRows===1 ) {
                            this.progCount++;
                            this.$refs["productInfoGrid"].deleteRecord(tIndex);
                        }else{
                            failCnt++;
                            await alertSync(this.$bvModal, {text:`[삭제 실패] ${ r.message }`});
                        }
                    }
                    await alertSync(this.$bvModal, {text:`삭제: ${this.progCount}건, 실패: ${failCnt}`});
                    this.$refs['productInfoGrid'].clearAllSelected();
                    await this.getProductInfoList();

                }catch(err){

                    console.log( err );
                }
            },




        }
    }
</script>
